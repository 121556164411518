import { all, call, put, takeLeading, delay } from "redux-saga/effects";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";

import {
  fetchSubmitCreditAppAsReq,
  DashSubmitCreditAppAsReqResType,
} from "@pd/api/dashboard/credit";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";

export default function* crudBuyerSagas() {
  yield all([
    takeLeading(da.credit.app.submit.toString(), onSubmitCreditAppAsRequestor),
  ]);
}

function* onSubmitCreditAppAsRequestor(
  action: ReturnType<typeof da.credit.app.submit>,
) {
  try {
    yield all([
      put(da.credit.app.apiFetching(true)),
      put(da.credit.app.apiSuccess(false)),
      put(da.credit.app.apiError({ message: "", status: 0 })),
    ]);
    const res: DashSubmitCreditAppAsReqResType = yield call(
      fetchSubmitCreditAppAsReq,
      action.payload.data,
      action.payload.sendEmail,
      action.payload.buyerId,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.credit.app.apiError(res.error)),
        put(da.credit.app.apiSuccess(false)),
        put(da.credit.app.apiFetching(false)),
      ]);
    } else {
      yield all([
        put(da.credit.app.setApiData(res.data)),
        put(da.credit.app.apiFetching(false)),
        put(da.credit.app.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while creating the buyer. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.credit.app.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
      put(da.credit.app.apiFetching(false)),
      put(da.credit.app.apiSuccess(false)),
    ]);
  }
}
