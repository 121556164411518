import { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import StitchLoading from "@pd/components/StitchLoading";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { Modal, Fade, Stack, Button, Typography } from "@mui/material";
import ModalTitle from "@pd/components/ModalTitle";
import {
  selectAllOrdersFetching,
  selectAllOrdersSuccess,
  selectAllOrdersError,
  selectBulkPayoutInfo,
  selectBulkPayoutResults,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/allOrders";
import { BulkPayoutResultError } from "@pd/redux/types/dbTypes";
import StitchSuccessIcon from "@pd/components/StitchIcons/StitchSuccessIcon";

import { ErrorDialogue, PayoutDialogue } from "./components";

const modalStyles = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  minWidth: "615px",
  borderRadius: "5px",
  transition: "height 0.3s ease-out",
};

const INITIAL_MODAL_HEIGHT = "420px";

type Props = {
  show: boolean;
  onClose: () => void;
};
export default function BulkPayoutModal(props: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectAllOrdersFetching);
  const apiSuccess = useAppSelector(selectAllOrdersSuccess);
  const apiError = useAppSelector(selectAllOrdersError);
  const bulkPayoutInfo = useAppSelector(selectBulkPayoutInfo);
  const bulkPayoutResults = useAppSelector(selectBulkPayoutResults);

  const [uiFetching, setUiFetching] = useState(false);
  const [uiSuccess, setUiSuccess] = useState(false);
  const [uiErrors, setUiErrors] = useState<BulkPayoutResultError[]>([]);
  const [modalHeight, setModalHeight] = useState(INITIAL_MODAL_HEIGHT);

  useEffect(() => {
    if (apiError.status && uiFetching) {
      setUiFetching(false);
      setUiSuccess(false);
    } else if (apiSuccess && uiFetching) {
      setUiFetching(false);
      if (bulkPayoutResults.errors?.length) {
        setUiSuccess(false);
        setUiErrors(bulkPayoutResults.errors);
      } else {
        setUiSuccess(true);
      }
    }
    if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [apiFetching, apiError, uiFetching, uiSuccess]);

  const handleFetchBulkPayoutInfo = useCallback(() => {
    dispatch(da.orders.all.getBulkPayoutsInfo());
    if (apiSuccess) {
      setUiSuccess(false);
      dispatch(da.orders.all.apiSuccess(false));
    }
  }, [apiSuccess]);

  const handleConfirmPayout = useCallback(() => {
    setUiFetching(true);
    dispatch(da.orders.all.triggerBulkPayout());
  }, []);

  const handleCloseModal = () => {
    props.onClose();
  };

  useEffect(() => {
    handleFetchBulkPayoutInfo();
    return () => {
      dispatch(da.orders.all.clearBulkPayoutInfo());
    };
  }, []);

  useEffect(() => {
    if (uiErrors.length) {
      setModalHeight("50vh");
    } else if (modalHeight !== INITIAL_MODAL_HEIGHT) {
      setModalHeight(INITIAL_MODAL_HEIGHT);
    }
  }, [uiErrors]);

  const renderContent = () => {
    if (apiError.status) {
      return (
        <>
          <Typography variant="h3">Sorry there was a problem</Typography>
          <Typography variant="body1">
            {"There was an issue while paying out. Please try again."}
          </Typography>
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              sx={{ width: "100px" }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={() => handleConfirmPayout()}
              sx={{ width: "100px" }}
            >
              Retry
            </Button>
          </Stack>
        </>
      );
    }
    if (uiFetching) {
      return <StitchLoading size="medium" />;
    }
    if (uiSuccess) {
      return (
        <Stack sx={{ p: 3, height: "100%" }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <Typography variant="h4">Orders paid out!</Typography>
            <StitchSuccessIcon />
          </Stack>
          <Button variant="contained" onClick={handleCloseModal}>
            Ok
          </Button>
        </Stack>
      );
    }

    if (uiErrors.length) {
      return (
        <ErrorDialogue
          bulkPayoutResults={bulkPayoutResults}
          uiErrors={uiErrors}
          onCloseModal={handleCloseModal}
        />
      );
    }
    return (
      <PayoutDialogue
        bulkPayoutInfo={bulkPayoutInfo}
        onConfirmPayout={handleConfirmPayout}
      />
    );
  };

  if (!props.show) return null;

  return (
    <Modal open disableEnforceFocus closeAfterTransition>
      <Fade in>
        <Stack
          sx={{
            ...modalStyles,
            height: modalHeight,
            transition: "height 1s ease-out",
          }}
        >
          <ModalTitle title="Bulk payout" onClose={handleCloseModal} />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="space-evenly"
            sx={{ height: "100%", flexGrow: 1 }}
          >
            {renderContent()}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
